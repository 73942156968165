<template>
  <app-header />
  <app-sidebar/>
  <section class="sign-in-section pb-120 pt-120 mt-lg-0 mt-sm-15 mt-10">
  <Suspense>
    <template #default>
      <app-login />
    </template>
    <template #fallback>
      <app-loading />
    </template>
  </Suspense>
</section>
  <app-footer />
</template>

<script>
import AppFooter from "../../components/layouts/AppFooter.vue";
import AppHeader from "../../components/layouts/AppHeader.vue";
import AppLogin from "../../components/Users/AppLogin.vue";
import AppLoading from "../../components/Spinners/AppLoading.vue";
import { Suspense } from "vue";
import AppSidebar from '../../components/layouts/AppSidebar.vue';
export default {
  components: { AppHeader, AppLogin, AppFooter, AppLoading, Suspense, AppSidebar },
  name: "Login",
};
</script>

<style>
</style>