<template>
    <div class="container pt-120">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="form-area">
                        <h1 class="tcn-1 text-center cursor-scale growUp mb-10">SIGN IN</h1>
                        <form action="#" class="sign-in-form">
                            <div class="single-input mb-6">
                                <input type="email" placeholder="Enter your email">
                            </div>
                            <div class="single-input mb-6">
                                <input type="password" placeholder="Enter your password">
                            </div>
                            <div class="text-center">
                                <button class="bttn py-3 px-6 rounded bgp-1">Sign In</button>
                            </div>
                        </form>
                        <p class="tcn-4 text-center mt-lg-10 mt-6">Don't have an account? <a href="signup.html"
                                class="text-decoration-underline tcp-1">Sign Up</a></p>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
  name: 'AppLogin',
}
</script>

<style scoped>
</style>